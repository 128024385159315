
import { TicketTypes, UserTicket } from "@/store/modules/ticket/ticket.types";
import { Component, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash } from "@/filters/utils";
import { UtilsMixin } from "@/mixins/utils.mixin";

const ticketX = namespace("Ticket");

@Component({
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
  },
  components: {
    ViewTicket: () => import("@/components/ticket/ViewTicket.vue"),
  },
})
export default class TopTicketsView extends Mixins(UtilsMixin) {
  @ticketX.Action(TicketTypes.LOAD_TOP_TICKETS)
  public getTopTicketTickets!: () => void;

  @ticketX.State(TicketTypes.TOP_TICKETS)
  public ticketTickets!: UserTicket[];

  @ticketX.State(TicketTypes.LOADING_TOP_TICKETS)
  public loadingTickets!: boolean;

  @ticketX.Mutation(TicketTypes.SET_VIEW_TICKET_DIALOG)
  public setViewTicket!: (viewTicket: boolean) => void;

  @ticketX.Mutation(TicketTypes.SET_TICKET_TICKET_DATA)
  public setUserTicketData!: (ticketTicket: UserTicket) => void;

  public headers: DataTableHeader[] = [
    {
      text: "#CASE NUMBER",
      sortable: false,
      value: "caseNumber",
      cellClass:
        "primary--text text--darken-2 font-weight-medium text-uppercase",
    },
    {
      text: "ISSUE",
      value: "issue",
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "REPORT DATE",
      value: "reportingDate",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "PRIORITY",
      value: "priority",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "SITE",
      value: "site",
      sortable: false,
      cellClass: "grey--text text--darken-2 text-uppercase",
    },
    {
      text: "STATUS",
      value: "status",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  viewTicket(item: UserTicket): void {
    this.setViewTicket(true);
    this.setUserTicketData(item);
  }

  mounted(): void {
    this.getTopTicketTickets();
  }
}
