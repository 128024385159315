import { Component, Vue } from "vue-property-decorator";

@Component
export class UtilsMixin extends Vue {
  public statusColor(status: string): string | undefined {
    switch (status) {
      case "New":
        return "primary";
      case "InProgress":
        return "#FFB400";
      case "Resolved":
        return "#57C900";
      case "Closed":
        return "#13B1FE";
      default:
        return undefined;
    }
  }
}
